import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { BrowserRouter, Navigate, Route, Routes, useNavigate, Redirect, useLocation } from 'react-router-dom';
import PrivateRoutes from "../../utils/PrivateRoutes";
import Login from "../Login";
import Index from "../Dashboards/Index";
import NotFound from "../NotFound/NotFound";
import Settings from "../Dashboards/Settings";
import $ from 'jquery';
import Swal from "sweetalert2";
import Services from "../Dashboards/Services/Services";
import CPs from "../Dashboards/CPs/CPs";
import CreateServices from "../Dashboards/Services/CreateServices";
import CreateCP from "../Dashboards/CPs/CreateCP";
import UnSubscription from "../Dashboards/UnSubscription/UnSubscription";
import { API_ROUTES } from "../../utils/constants";
import Revenues from "../Dashboards/RevenueReport/Revenues";
import LoadingSpinner from "../Dashboards/LoadingSpinner/LoadingSpinner";
import Subscription from "../Dashboards/Subscription/Subscription";



export default function RouterComponent({ getRandomThreeDigitNumber }){

    const userData = JSON.parse(localStorage.getItem('userDataFs'));
    const navigate = useNavigate();

    const objectToFormData = (obj) => {
        const formData = new FormData();      
        Object.entries(obj).forEach(([key, value]) => {
          formData.append(key, value);
        });
        return formData;
    }

    
    const capitalizeWords = (string) => {
        const words = string.split(' ');
        const capitalizedWords = words.map((word) => {
          return word.charAt(0).toUpperCase() + word.slice(1);
        });
        return capitalizedWords.join(' ');
    }

    const errorFunction = (error) => {
        if(error.response.status.toString() === "400" || error.response.status.toString() === "401"){
            Swal.fire({
                title: "Error",
                text: error.response.data.message,
                icon: "error"
            });
        }else{
            Swal.fire({
                title: "Error",
                text: error,
                icon: "error"
            });
        }
    }


    useEffect(() => {
        var flapt_window = $(window);
        flapt_window.on('load', function () {
            $('#preloader').fadeOut('slow', function () {
                $(this).remove();
            });
        });
    }, []);



    const isAuthExpired = (responseJSON) => {
        if(responseJSON.status.toString() === "900" || responseJSON.message.toString() === "Authentication Failed"){
            localStorage.removeItem('userDataFs');
            navigate("/login");
            return true;
        }else{
            return false;
        }
    }


    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    const today = new Date();

    function resetTimeToMidnight(dateTimeStr) {
        const [date, _] = dateTimeStr.split(' ');
        return `${date} 00:00:00`;
    }

    function resetTimeToB4Midnight(dateTimeStr) {
        const [date, _] = dateTimeStr.split(' ');
        return `${date} 23:59:59`;
    }

    const formatDate1 = (date) => {
        if (!date) return '';
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    };




    const location = useLocation();
    const [allCPs, setAllCPs] = useState([]);
    const [isLoading1, setIsLoading1] = useState(false);

    const getAllCPS = async () => {
        setIsLoading1(true);
        try {
            const response = await fetch(API_ROUTES.VIEW_CP, {
                method: "POST",
                headers: {'token': global.tokens || userData?.responseDetails?.token},
            });
            const responseJSON = await response.json();

            if(global.tokens === ""){
                setIsLoading1(false);
            }
            if (responseJSON.status.toString() === "200") {
                const responseData = responseJSON.data;
                console.log("getAllCPS");
                console.log(responseData);
                setAllCPs(responseData);
                setIsLoading1(false);
            }
        } catch (error) {
            console.log('Error getting data: ', error);
            setAllCPs([]);
            setIsLoading1(false);
        }
    }


    useEffect(() => {
        getAllCPS();
    }, [global.tokens]);
    


    return (
        <>
        <Routes>
            <Route path="" element={<Login objectToFormData={objectToFormData} />} />
            <Route path="/login" element={<Login objectToFormData={objectToFormData} />} />

            <Route path="*" element={<NotFound />} />

            <Route path="/dashboard" element={
                <PrivateRoutes redirectTo="/login">
                    <Index Helmet={Helmet} capitalizeWords={capitalizeWords} isAuthExpired={isAuthExpired} objectToFormData={objectToFormData} yesterday={yesterday} today={today} resetTimeToMidnight={resetTimeToMidnight} resetTimeToB4Midnight={resetTimeToB4Midnight} formatDate={formatDate1} allCPs={allCPs} isLoading1={isLoading1} />
                </PrivateRoutes>
            }/>


            <Route path="/settings" element={
                <PrivateRoutes redirectTo="/login">
                    <Settings Helmet={Helmet} objectToFormData={objectToFormData} capitalizeWords={capitalizeWords} errorFunction={errorFunction} isAuthExpired={isAuthExpired} />
                </PrivateRoutes>
            }/>

            <Route path="/service-list" element={
                <PrivateRoutes redirectTo="/login">
                    <Services Helmet={Helmet} objectToFormData={objectToFormData} capitalizeWords={capitalizeWords} errorFunction={errorFunction} isAuthExpired={isAuthExpired} getRandomThreeDigitNumber={getRandomThreeDigitNumber} today={today}  />
                </PrivateRoutes>
            }/>

            
            <Route path="/create-service" element={
                <PrivateRoutes redirectTo="/login">
                    <CreateServices Helmet={Helmet} objectToFormData={objectToFormData} capitalizeWords={capitalizeWords} errorFunction={errorFunction} isAuthExpired={isAuthExpired} allCPs={allCPs} isLoading1={isLoading1} />
                </PrivateRoutes>
            }/>

            <Route path="/edit-service/:name/" element={
                <PrivateRoutes redirectTo="/login">
                    <CreateServices Helmet={Helmet} objectToFormData={objectToFormData} capitalizeWords={capitalizeWords} errorFunction={errorFunction} isAuthExpired={isAuthExpired} allCPs={allCPs} isLoading1={isLoading1} today={today}  />
                </PrivateRoutes>
            }/>

            <Route path="/view-cp" element={
                <PrivateRoutes redirectTo="/login">
                    <CPs Helmet={Helmet} objectToFormData={objectToFormData} capitalizeWords={capitalizeWords} errorFunction={errorFunction} isAuthExpired={isAuthExpired} getRandomThreeDigitNumber={getRandomThreeDigitNumber} />
                </PrivateRoutes>
            }/>

            <Route path="/create-cp" element={
                <PrivateRoutes redirectTo="/login">
                    <CreateCP Helmet={Helmet} objectToFormData={objectToFormData} capitalizeWords={capitalizeWords} errorFunction={errorFunction} isAuthExpired={isAuthExpired} />
                </PrivateRoutes>
            }/>

            <Route path="/edit-cp/:name/" element={
                <PrivateRoutes redirectTo="/login">
                    <CreateCP Helmet={Helmet} objectToFormData={objectToFormData} capitalizeWords={capitalizeWords} errorFunction={errorFunction} isAuthExpired={isAuthExpired} />
                </PrivateRoutes>
            }/>

            <Route path="/revenue-report" element={
                <PrivateRoutes redirectTo="/login">
                    <Revenues Helmet={Helmet} objectToFormData={objectToFormData} capitalizeWords={capitalizeWords} errorFunction={errorFunction} isAuthExpired={isAuthExpired} formatDate={formatDate1} allCPs={allCPs} isLoading1={isLoading1} resetTimeToMidnight={resetTimeToMidnight} resetTimeToB4Midnight={resetTimeToB4Midnight} today={today}  />
                </PrivateRoutes>
            }/>

            <Route path="/unsubscription" element={
                <PrivateRoutes redirectTo="/login">
                    <UnSubscription Helmet={Helmet} objectToFormData={objectToFormData} capitalizeWords={capitalizeWords} errorFunction={errorFunction} isAuthExpired={isAuthExpired} yesterday={yesterday} today={today} resetTimeToMidnight={resetTimeToMidnight} resetTimeToB4Midnight={resetTimeToB4Midnight} formatDate={formatDate1} allCPs={allCPs} isLoading1={isLoading1} />
                </PrivateRoutes>
            }/>

            <Route path="/subscription" element={
                <PrivateRoutes redirectTo="/login">
                    <Subscription Helmet={Helmet} objectToFormData={objectToFormData} capitalizeWords={capitalizeWords} errorFunction={errorFunction} isAuthExpired={isAuthExpired} yesterday={yesterday} today={today} resetTimeToMidnight={resetTimeToMidnight} resetTimeToB4Midnight={resetTimeToB4Midnight} formatDate={formatDate1} allCPs={allCPs} isLoading1={isLoading1} />
                </PrivateRoutes>
            }/>

            

        </Routes>
        </>
    );
}